<template>
    <div>
        <el-card>
            <nav class="input" style="width: auto">
                <nav class="input" style="width: auto">
                    <el-button type="primary" size="small" @click="add">{{
                        '新建积分券'
                    }}</el-button>
                </nav>
            </nav>
        </el-card>

        <el-card style="margin-top: 10px">
            <el-table
                :data="list"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
            >
                <el-table-column label="ID" prop="id"> </el-table-column>
                <el-table-column prop="name" label="积分券名称" />
                <el-table-column prop="bonus" label="赠送积分" />
                <el-table-column prop="bonus" label="领取有效期">
                    <template slot-scope="{ row }">
                        {{ row.beginTime + ' 至 ' + row.endTime }}
                    </template>
                </el-table-column>
                <el-table-column prop="bonusEndTime" label="积分有效期">
                    <template slot-scope="{ row }">
                        {{ row.bonusEndTime }}天
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="领取/总数">
                    <template slot-scope="{ row }">
                        <span class="click" @click="toReceive(row)">{{
                            `${row.getNum}/${row.number}`
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="{ row }">
                        <el-tag :type="row.status ? 'warning' : 'success'">{{
                            row.status ? '停止' : '启用'
                        }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="remask" label="备注">
                    <template slot-scope="{ row }">
                        <Remark
                            :row="row"
                            url="/admin/BonusCoupon/editRemark"
                        ></Remark>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="二维码">
                    <template slot-scope="{ row }">
                        <el-image
                            style="width: 80px"
                            :src="baseurl + yu + row.sunCode"
                            :preview-src-list="[baseurl + yu + row.sunCode]"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="{ row }">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            style="color: skyblue"
                            @click="editor(row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            style="color: red"
                            @click="deletes(row)"
                            >删除</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-s-promotion"
                            size="mini"
                            style="color: pink"
                            @click="shelves(row)"
                            >{{ row.status == 0 ? '停用' : '启用' }}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="search.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="search.pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
            />
        </el-card>
    </div>
</template>

<script>
import list from '/src/mixin/list.js'
export default {
    mixins: [list],
    data() {
        return {
            search: {
                page: 1,
                pageSize: 10,
            },
        }
    },
    created() {
        this.getlist()
    },
    methods: {
        add() {
            this.$router.push({
                path: '/bonus_coupon_add',
            })
        },
        toReceive(row) {
            this.$router.push({
                path: '/bonus_coupon_detail',
                query: {
                    id: row.id,
                },
            })
        },
        async deletes(row) {
            try {
                await this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                let { data: res } = await this.$http.delete(
                    '/admin/BonusCoupon/delete',
                    {
                        params: {
                            id: row.id,
                        },
                    }
                )
                if (res.errorCode == 200) {
                    this.$message.success(res.message)
                    if (this.total % 10 == 1) {
                        this.search.page--
                    }
                    this.getlist()
                }
            } catch (error) {
                this.$message({
                    type: 'info',
                    message: '已取消删除',
                })
            }
        },
        async shelves(row) {
            // console.log(row.status);
            const { data: res } = await this.$http.post(
                '/admin/BonusCoupon/updateStatus',
                {
                    id: row.id,
                    status: row.status ? 0 : 1,
                }
            )
            if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.getlist()
            }
        },
        async getlist() {
            let { data: res } = await this.$http.get(
                '/admin/BonusCoupon/getList',
                {
                    params: {
                        ...this.search,
                    },
                }
            )
            if (res.errorCode == 200) {
                this.list = res.data.list
                this.total = res.data.totalCount
            }
        },
        handleCurrentChange(e) {
            this.search.page = e
            this.getlist()
        },
        editor(row) {
            this.$router.push({
                path: '/bonus_coupon_add',
                query: {
                    id: row.id,
                },
            })
        },
        subimt() {},
    },
}
</script>

<style lang="less" scoped>
.click {
    color: #0000ee;
    text-decoration: underline;
    cursor: pointer;
}
</style>
